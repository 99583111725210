import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_bng_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import Image1 from '../assets/images/photos/slide/slide-3_cropped.webp';
import Image2 from '../assets/images/photos/slide/slide-4_cropped.webp';
import Services from "../components/Services";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_bng_lps.js");
  const HeroContent ={
    image1 : Image1,
    image2: Image2,  
    Heading: `<span class="color1">Settle Your</span>
          <span class="color2"> Tax Debt </span><br/>
          <span class="color3">for Less</span>` , 
    subtext: `Professional Assistance to Help You Save Thousands` ,
    Heading2: `FREE CONSULTATION!`


  }
  const ConactText = {
    Text: `Get Out Of Tax Debt For <br/> Good`
  }
  const PhoneNumber = {
    PhoneNo: " (866) 459-3111",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber}/>
      
      <Services PhoneNumber={PhoneNumber} />
      <Contact ConactText={ConactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
