import React, { useEffect, useRef } from "react";
import scrollAnimation from "../Utility/scrollAnimation";
export default function Contact({ PhoneNumber, ConactText }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="Contact">
      <div className="overlay">
        <div className="ct_cont">
          <h1
            className="big-heading"
            dangerouslySetInnerHTML={{ __html: ConactText.Text }}
          />

          <div className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
              {" "}
              CALL NOW:{PhoneNumber.PhoneNo}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
