import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(844) 550-1405",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="103"
        class="elementor elementor-103"
      >
        
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-8d672ab elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="8d672ab"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a86aa9"
              data-id="5a86aa9"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-fdba073 elementor-widget elementor-widget-heading"
                  data-id="fdba073"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      DISCLAIMER
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8eacb4d elementor-widget elementor-widget-text-editor"
                  data-id="8eacb4d"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>Last updated July 25, 2022</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-129319b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="129319b"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1136e00"
              data-id="1136e00"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-686bdb3 elementor-widget elementor-widget-text-editor"
                  data-id="686bdb3"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The information provided by Permanent Tax Relief (“we,”
                      “us,” or “our”) on firstrateautowarranty.com (the “Site”)
                      is for general informational purposes only. All
                      information on the Site is provided in good faith, however
                      we make no representation or warranty of any kind, express
                      or implied, regarding the accuracy, adequacy, validity,
                      reliability, availability, or completeness of any
                      information on the Site. UNDER NO CIRCUMSTANCE SHALL WE
                      HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
                      RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
                      OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE
                      SITE IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-2d52698 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="2d52698"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1d36d0d"
              data-id="1d36d0d"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-9b5cad5  elementor-widget elementor-widget-heading"
                  data-id="9b5cad5"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      PROFESSIONAL DISCLAIMER
                    </h4>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-d374c6a elementor-widget elementor-widget-text-editor"
                  data-id="d374c6a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The Site cannot and does not contain Tax Debt Relief
                      advice. The Tax Debt Relief information is provided for
                      general informational and educational purposes only and is
                      not a substitute for professional advice. Accordingly,
                      before taking any actions based upon such information, we
                      encourage you to consult with the appropriate
                      professionals. We do not provide any kind of Tax Debt
                      Relief advice. THE USE OR RELIANCE OF ANY INFORMATION
                      CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-a4bffdf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a4bffdf"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0dc71b5"
              data-id="0dc71b5"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-0035655  elementor-widget elementor-widget-heading"
                  data-id="0035655"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      TCPA DISCLAIMER
                    </h4>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-4b8dec8 elementor-widget elementor-widget-text-editor"
                  data-id="4b8dec8"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by
                      PERMANENT TAX RELIEF, our agents, representatives,
                      affiliates, or anyone calling on our behalf. This is for
                      any purposes relating to your request for Tax Debt Relief
                      service, at any telephone number, or physical or
                      electronic address you provide or at which you may be
                      reached. You agree we may contact you in any way,
                      including SMS messages (text messages), calls using
                      prerecorded messages or artificial voice, and calls and
                      messages delivered using auto telephone dialing system or
                      an automatic texting system.
                    </p>
                    <p>
                      Automated messages may be played when the telephone is
                      answered whether by you or someone else. In the event that
                      an agent or representative of PERMANENT TAX RELIEF calls,
                      he or she may also leave a message on your answering
                      machine, voice mail, or send one via text. You certify
                      that the telephone numbers that you have provided, are in
                      fact your contact numbers, and you agree to receive calls
                      at each phone number you have provided PERMANENT TAX
                      RELIEF. You agree to promptly alert PERMANENT TAX RELIEF
                      if you stop using a particular phone number. Your
                      cell/mobile telephone provider will charge you according
                      to the type of account you carry with those companies. You
                      may opt out of any future contact via text message by
                      replying anytime with “STOP”. You agree that PERMANENT TAX
                      RELIEF may contact you by email, using any email address
                      you have provided or that you provide in the future. You
                      may opt out of any future contact via email message by
                      replying anytime with “UNSUBSCRIBE”. PERMANENT TAX RELIEF
                      may listen to and/or record calls between you and any
                      representative without notice, as permitted by applicable
                      laws. For example we listen to calls to monitor for
                      quality purposes.
                    </p>
                    <div>&nbsp;</div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
