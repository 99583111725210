import React, { useEffect, useRef } from "react";
import scrollAnimation from "../Utility/scrollAnimation";
import icon1 from "../assets/images/icons/confidential-folder.png";
import icon2 from "../assets/images/icons/dependable.png";
import icon3 from "../assets/images/icons/multitasking.png";
import icon4 from "../assets/images/icons/saving.png";

export default function Services({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  const services = [
    {
      icon: icon4,
      title: "Save Money",
      text: "Resolve your tax debt for a fraction of the cost. Depending on your situation, you could save thousands of dollars!",
    },
    {
      icon: icon3,
      title: "Experienced Professionals",
      text: "Connecting you with enrolled agents, certified public accountants (CPAs), and tax attorneys who have extensive experience dealing with the IRS and state governments, so you get the best possible settlement.",
    },
    {
      icon: icon1,
      title: "Secure And Confidential",
      text: "All information you provide is secure and will be kept strictly confidential. We understand how sensitive tax debt problems can be and will treat you with the respect you deserve.",
    },
    {
      icon: icon2,
      title: "Trusted And Reliable",
      text: "We are a trusted and reliable source for connecting you with tax debt relief experts. We have helped thousands of people get out of tax debt, and we can help you too.",
    },
  ];

  return (
    <div className="se">
      <div className="Services">
        <div className="left-content">
          <p className="subheading">WHY CHOOSE US</p>
          <h2 className="heading">Get Out of Tax Debt for Good</h2>
        </div>
        <div className="right-cards">
          {services.map((service, index) => (
            <div
              className={`card card-${index}`}
              key={index}
              ref={cardRefs[index]}
            >
              <div className="icon">
                <img src={service.icon} alt="" />
              </div>
              <div className="card-content">
                <h3>{service.title}</h3>
                <p>{service.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="btn-group">
        <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
      </div>
    </div>
  );
}
