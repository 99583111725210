import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_bng_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import Image1 from '../assets/images/photos/slide/slide-5_cropped.png';
import Image2 from '../assets/images/photos/slide/slide-6_cropped.jpg';
import Services from "../components/Services";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_bng_lps.js");
  const HeroContent ={
    image1 : Image1,
    image2: Image2,  
    Heading: `<span class="color1">Say Goodbye</span>
          <span class="color2">  to IRS  </span><br/>
          <span class="color3">Debt Forever</span>` , 
    subtext: `Discover Proven Strategies to Settle Your Tax Debt for Less` ,
    Heading2: `FREE CONSULTATION!`


  }
  const ConactText = {
    Text: `End Your Tax Debt Worries <br/> Today`
  }
  const PhoneNumber = {
    PhoneNo: " (866) 459-3111",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber}/>
      
      <Services PhoneNumber={PhoneNumber} />
      <Contact ConactText={ConactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
