import React, { useEffect, useRef } from "react";
import scrollAnimation from "../Utility/scrollAnimation";
import Image from "../assets/images/photos/slide/Group 27 (2).png";
import Ic from "../assets/images/it.png";
export default function WhyChooseUs({ PhoneNumber }) {
  const leftImageRef = useRef(null);
  const rightContentRef = useRef(null);

  useEffect(() => {
    if (leftImageRef.current) {
      scrollAnimation.observeElement(leftImageRef.current);
    }
    if (rightContentRef.current) {
      scrollAnimation.observeElement(rightContentRef.current);
    }
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  const features = [
    { text: "Resolve Back Taxes",
      icon: Ic
     },
    { text: "Tax Negotiation & Settlement",
      icon: Ic },
    { text: "End Penalties & Interest",
      icon: Ic },
    { text: "Wage Garnishment",
      icon: Ic },
    { text: "Tax Levies & Liens",
      icon: Ic },
    { text: "Reduce IRS Tax Debt",
      icon: Ic },
    { text: "Payroll Tax Negotiation",
      icon: Ic },
    { text: "IRS Audit Defense",
      icon: Ic },
  ];

  return (
    <div className="WhyChooseUs">
      <div className="left-image" ref={leftImageRef}>
        <img src={Image} alt="Why Choose Us" />
      </div>
      <div className="right-content" ref={rightContentRef}>
        <p className="subheading">HOW WE CAN HELP</p>
        <h2 className="heading">Get the relief you deserve</h2>
        <div className="features-list">
          {features.map((feature, index) => (
            <div className="feature-item" key={index}>
              <div className="text-content">
                <ul>
                  <li>
                    <p><img src={feature.icon} alt="" /> {feature.text}</p>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}> CALL NOW: {PhoneNumber.PhoneNo}</a>
        </div>
      </div>
    </div>
  );
}
