import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import scrollAnimation from "../Utility/scrollAnimation";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HeroSection({ PhoneNumber, HeroContent }) {
  const formatPhoneNumberForTel = (number) => number.replace(/\D/g, "");
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,
    arrows: false,
  };

  const images = [HeroContent.image1, HeroContent.image2];

  const leftContentRef = useRef(null);

  useEffect(() => {
    scrollAnimation.observeElement(leftContentRef.current);
  }, []);

  return (
    <div className="HeroSection">
      <div className="left-content" ref={leftContentRef}>
        <h1
          className="heading1"
          dangerouslySetInnerHTML={{ __html: HeroContent.Heading }}
        />
        <p className="subheading">{HeroContent.subtext}</p>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
        </div>
      </div>
      <div className="right-slider">
        <Slider {...settings}>
          {images.map((src, index) => (
            <div key={index}>
              <img src={src} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
