import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import Image1 from './assets/images/photos/slide/slide-1_cropped.webp';
import Image2 from './assets/images/photos/slide/slide-2_cropped.webp';
export default function landingPage() {
  const HeroContent ={
    image1 : Image1,
    image2: Image2,
    Heading: `<span class="color1">End Your</span>
          <span class="color2"> Tax Debt </span> <br/>
          <span class="color3">Problems Today</span>` , 
    subtext: `Partner with Leading Tax Resolution Specialists`

  }
  const ConactText = {
    Text: `Say Goodbye to Tax Debt <br/>
Forever`
  }
  const PhoneNumber = {
    PhoneNo: "(844) 550-1405",
  };
  
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber}/>
      <HowItWorks PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Services PhoneNumber={PhoneNumber} />
      <Contact ConactText={ConactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
