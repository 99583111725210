import React, { useEffect, useRef } from "react";
import scrollAnimation from "../Utility/scrollAnimation";
import logo from "../assets/images/logo-300x70.webp";
import { Link } from "react-router-dom";
export default function Footer({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div>
      <div className="Footer">
        <div className="Footer1">
          {" "}
          <div className="f-logo">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="f-pages">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
          <div className="f-contact">
            <h4>Contact</h4>
            <ul>
              <li>
                <a href="mailto:info@permanenttaxrelief.com">
                  info@permanenttaxrelief.com
                </a>{" "}
              </li>
              <li>
                <a href={`tel:${telPhoneNumber}`}> {PhoneNumber.PhoneNo}</a>{" "}
              </li>
            </ul>
          </div>{" "}
        </div>
        <span>
          © Copyright 2024 Permanent Tax Relief | All Rights Reserved.
        </span>
      </div>
    </div>
  );
}
