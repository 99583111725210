import React, { useEffect, useRef } from "react";
import scrollAnimation from "../Utility/scrollAnimation";

export default function HowItWorks({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="HowWorks">
      <div>
        <h2>You Can Depend On Our Tax Pros!</h2>
        <p>
          We guide you to the finest tax professionals ready to address your
          specific needs. Whether you're facing IRS issues, tax debt, or need
          expert advice, our network of experienced tax specialists is here to
          help. We make it easy for you to find the right support and solutions,
          so you can regain your financial peace of mind.
        </p>
      </div>
    </div>
  );
}
