import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function TermsCondition() {
  const PhoneNumber = {
    PhoneNo: "(844) 550-1405",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="82"
        class="elementor elementor-82"
      >
       
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e438826 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e438826"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b868ebf"
              data-id="b868ebf"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-b191a53 elementor-widget elementor-widget-heading"
                  data-id="b191a53"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h2 class="elementor-heading-title elementor-size-default">
                      TERMS AND CONDITIONS
                    </h2>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-6415c94 elementor-widget elementor-widget-text-editor"
                  data-id="6415c94"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      These Terms and Conditions constitute a legally binding
                      agreement made between you, whether personally or on
                      behalf of an entity (“you”) and Permanent Tax Relief
                      (“we,” “us,” or “our”), concerning your access to and use
                      of the permanenttaxrelief.com website as well as any other
                      media form, media channel, mobile website or mobile
                      application related, linked, or otherwise connected to it
                      (collectively, the “Site”).&nbsp;
                    </p>
                    <p>
                      You agree that by accessing the Site, you have read,
                      understood, and agree to be bound by all of these Terms
                      and Conditions. If you do not agree with all of these
                      Terms and Conditions, you are expressly prohibited from
                      using the Site and must discontinue use immediately.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-e267f78 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e267f78"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-390d1df"
              data-id="390d1df"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-45374b7  elementor-widget elementor-widget-heading"
                  data-id="45374b7"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      INTELLECTUAL PROPERTY
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-4934bda elementor-widget elementor-widget-text-editor"
                  data-id="4934bda"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      The Site may contain (or you may be sent via the Site)
                      links to other websites (“Third-Party Websites”) as well
                      as articles, photographs, text, graphics, pictures,
                      designs, music, sound, video, information, applications,
                      software, and other content or items belonging to or
                      originating from third parties (“Third-Party
                      Content”).&nbsp;
                    </p>{" "}
                    <p>
                      Such Third-Party Websites and Third-Party Content are not
                      investigated, monitored, or checked for accuracy,
                      appropriateness, or completeness by us. We are not
                      responsible for any Third-Party Websites accessed through
                      the Site or any Third-Party Content posted on, available
                      through, or installed from the Site, including the
                      content, accuracy, offensiveness, opinions, reliability,
                      privacy practices, or other policies of or contained in
                      the Third-Party Websites or the Third-Party Content.&nbsp;
                    </p>{" "}
                    <p class="nitro-offscreen">
                      Inclusion of, linking to, or permitting the use or
                      installation of any Third-Party Websites or any
                      Third-Party Content does not imply approval or endorsement
                      thereof. If you decide to leave the Site and access the
                      Third-Party Websites or to use or install any Third-Party
                      Content, you do so at your own risk, and you should be
                      aware these Terms and Conditions no longer govern.&nbsp;
                    </p>
                    <p class="nitro-offscreen">
                      We strongly advise you to read the Third-Party Website
                      terms and conditions and the privacy policies of any site
                      or services you visit or use.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-36fdcd5 elementor-section-boxed elementor-section-height-default nitro-offscreen"
          data-id="36fdcd5"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fdab51a"
              data-id="fdab51a"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-cb316c0  elementor-widget elementor-widget-heading"
                  data-id="cb316c0"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      INDEMNIFICATION
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-bb7c091 elementor-widget elementor-widget-text-editor"
                  data-id="bb7c091"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      You agree to defend, indemnify, and hold Permanent Tax
                      Relief harmless, including our subsidiaries, affiliates,
                      and all of our respective officers, agents, partners, and
                      employees, from and against any loss, damage, liability,
                      claim, or demand, including reasonable attorneys’ fees and
                      expenses, made by any third party due to or arising out
                      of:
                    </p>
                    <p>
                      (1) use of the Site
                      <br />
                      <span>
                        (2) breach of these Terms and Conditions
                        <br />
                      </span>
                      <span >
                        (3) any breach of your representations and warranties
                        outlined in these Terms and Conditions
                        <br />
                      </span>
                      <span >
                        (4) your violation of the rights of a third party,
                        including but not limited to intellectual property
                        rights
                        <br />
                      </span>
                      <span>
                        (5) any overt harmful act toward any other user of the
                        Site with whom you connected via the Site
                      </span>
                    </p>
                    <div>&nbsp;</div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-f006804 elementor-section-boxed elementor-section-height-default nitro-offscreen"
          data-id="f006804"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-53f66af"
              data-id="53f66af"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-2fb16d1  elementor-widget elementor-widget-heading"
                  data-id="2fb16d1"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      LIMITATION OF LIABILITY
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-d04da55 elementor-widget elementor-widget-text-editor"
                  data-id="d04da55"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      In no event will we or our directors, employees, or agents
                      be liable to you or any third party for any direct,
                      indirect, consequential, exemplary, incidental, special,
                      or punitive damages, including lost profit, lost revenue,
                      loss of data, or other damages arising from your use of
                      the site, even if we have been advised of the possibility
                      of such damages.
                    </p>
                    <p>
                      Notwithstanding anything to the contrary contained herein,
                      our liability to you for any cause whatsoever and
                      regardless of the form of the action will at all times be
                      limited to the lesser of the amount paid, if any, by you
                      to us during the ONE (1) month period before any cause of
                      action arising or US$100. State laws may not allow
                      limitations on implied warranties or the exclusion or
                      limitation of certain damages.
                    </p>
                    <p>
                      If certain State laws apply to you, some or all
                      disclaimers found within these terms and conditions or
                      limitations may not apply to you, and you may have
                      additional rights.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-96278a3 elementor-section-boxed elementor-section-height-default nitro-offscreen"
          data-id="96278a3"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d4f6ce3"
              data-id="d4f6ce3"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-a4ff21c  elementor-widget elementor-widget-heading"
                  data-id="a4ff21c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      EXCLUSIONS
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-77ae8a0 elementor-widget elementor-widget-text-editor"
                  data-id="77ae8a0"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      While the limitations above may not apply to you, some
                      countries do not allow exclusions or limitations of
                      liability for consequential damages, so please check with
                      your jurisdiction before purchasing this insurance.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-2058a6f elementor-section-boxed elementor-section-height-default nitro-offscreen"
          data-id="2058a6f"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-688ed4c"
              data-id="688ed4c"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-56652a3  elementor-widget elementor-widget-heading"
                  data-id="56652a3"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      GOVERNING LAW
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-085bfe6 elementor-widget elementor-widget-text-editor"
                  data-id="085bfe6"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      These Terms and Conditions and your use of the Site are
                      governed by and construed following the laws of the State
                      of Florida applicable to agreements made and to be
                      entirely performed within the State of Florida, without
                      regard to its conflict of law principles.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-5c775c4 elementor-section-boxed elementor-section-height-default nitro-offscreen"
          data-id="5c775c4"
          data-element_type="section"
        >
          {" "}
          <div class="elementor-container elementor-column-gap-default">
            {" "}
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6889828"
              data-id="6889828"
              data-element_type="column"
            >
              {" "}
              <div class="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  class="elementor-element elementor-element-ab808b3  elementor-widget elementor-widget-heading"
                  data-id="ab808b3"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <h4 class="elementor-heading-title elementor-size-default">
                      GOVERNING LAW
                    </h4>{" "}
                  </div>{" "}
                </div>{" "}
                <div
                  class="elementor-element elementor-element-c3544c0 elementor-widget elementor-widget-text-editor"
                  data-id="c3544c0"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  {" "}
                  <div class="elementor-widget-container">
                    {" "}
                    <p>
                      Supplemental terms and conditions or documents posted on
                      the Site from time to time are hereby expressly
                      incorporated herein by reference. In our sole discretion,
                      we reserve the right to make changes or modifications to
                      these Terms and Conditions at any time and for any reason.
                    </p>
                    <p>
                      We will alert you about any changes by updating the “Last
                      Updated” date of these Terms and Conditions, and you waive
                      any right to receive specific notice of each such change.
                    </p>
                    <p>
                      You must periodically review these Terms and Conditions to
                      stay informed of updates. You will be subject to and will
                      be deemed to have been made aware of and accepted the
                      changes in any revised Terms and Conditions by your
                      continued use of the Site after the date such revised
                      Terms and Conditions are posted.
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
